// Odoo User Service
import axios from 'axios';

export const API_BASE_URL = process.env.VUE_APP_BACKEND_URL || 'https://laser.rampslogistics.com:443'
export const odooUserService = {
    login,
    // logout,
    // register,
    // getAll,
};

// Set headers for the preflight (OPTIONS) request
const options = {
    headers: {
        'Content-Type': 'application/json',
    },
};

async function login(email, password) {
    try {
        const requestData = new URLSearchParams();
        requestData.append('email', email);
        requestData.append('password', password);

        const options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const response = await axios.post(`${API_BASE_URL}/api/portal/authenticate`, requestData, options);
        console.log(response)
        let token = response.data.token
        if(token){
            localStorage.setItem('user', token);
        }

        // Handle the response and return user data
        return response.data;
    } catch (error) {
        // Handle error
        throw error;
    }
}

// Implement logout, register, and getAll similarly...

