import store from '@/state/store'
import {
    userProfileService
  } from '@/helpers/odooService/user_profile.service'

import { menuItems, menus } from "@/components/horizontal-menu";

export default [
    {
    path: '/',
    meta: {
        authRequired: true
    },
    name: 'home',
    component: () => import('./views/pages/material-requests'),
    beforeEnter: async (to, from, next) => {
        try {
          // Fetch user profile
          const userProfile = await userProfileService.getUserProfile();
          const menuCode = userProfile.profile_menu_id.code;
          const userMenuItems = menus[menuCode];
  
          // Check if the target route matches any menu item
          const matchingMenuItem = userMenuItems.find(item => item.link === to.path);
  
          if (!matchingMenuItem && userMenuItems.length > 0) {
            // Redirect to the first menu item if no match is found
            return next(userMenuItems[0].link);
          }
  
          next();
        } catch (error) {
          // Handle error (e.g., redirect to a login page if user profile fetch fails)
          console.error('Failed to fetch user profile:', error);
          return next('/login');
        }
      }
  
},
{
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
        title: "Login",
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home page instead
                next({
                    name: 'home'
                })
            } else {
                // Continue to the login page
                next()
            }
        },
    },
},
{
    path: '/register',
    name: 'register',
    component: () => import('./views/account/register'),
    meta: {
        title: "Register",
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home page instead
                next({
                    name: 'home'
                })
            } else {
                // Continue to the login page
                next()
            }
        },
    },
},
{
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/account/forgot-password'),
    meta: {
        title: "Forgot password",
        beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters['auth/loggedIn']) {
                // Redirect to the home page instead
                next({
                    name: 'home'
                })
            } else {
                // Continue to the login page
                next()
            }
        },
    },
},
{
    path: '/logout',
    name: 'logout',
    meta: {
        title: "Logout",
        authRequired: true,
        beforeResolve(routeTo, routeFrom, next) {
            if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                store.dispatch('auth/logOut')
            } else {
                store.dispatch('authfack/logout')
            }
            const authRequiredOnPreviousRoute = routeFrom.matched.some(
                (route) => route.push('/login')
            )
            // Navigate back to previous page, or home as a fallback
            next(authRequiredOnPreviousRoute ? {
                name: 'default'
            } : {
                ...routeFrom
            })
        },
    },
},
{
    path: '/pages/blank-page',
    name: 'Blank page',
    meta: { title: "Blank Page", authRequired: true },
    component: () => import('./views/pages/blank'),
},
{
    path: '/pages/create-material-request',
    name: 'create-material-request',
    meta: { title: "Create Material Loadout", authRequired: true },
    component: () => import('./views/pages/create-material-request'),
},
{
    path: '/pages/create-delivery-request',
    name: 'create-delivery-request',
    meta: { title: "Create Vendor Delivery Request", authRequired: true },
    component: () => import('./views/vendor-delivery/create-delivery-request'),
},
{
    path: '/pages/material-requests',
    name: 'Material Requests',
    meta: { title: "Material Requests", authRequired: true },
    component: () => import('./views/pages/material-requests'),
},
{
    path: '/pages/delivery-requests-detail/:ids?',
    name: 'delivery-requests-detail',
    meta: { title: "Vendor Delivery Request(s) Detail", authRequired: true },
    component: () => import('./views/vendor-delivery/delivery-requests-detail'),
},

{
    path: '/pages/create-cargo-manifest',
    name: 'create-cargo-manifest',
    meta: { title: "Create Cargo Manifest", authRequired: true },
    component: () => import('./views/manifest/create-manifest'),
},
{
    path: '/pages/cargo-manifests/:movement?',
    name: 'cargo-manifests',
    meta: { title: "Cargo Manifests", authRequired: true },
    component: () => import('./views/manifest/cargo-manifests'),
    props: true, // Pass the route parameter as a prop
},
{
    path: '/pages/cargo-manifests-detail/:ids?',
    name: 'cargo-manifests-detail',
    meta: { title: "Cargo Manifest Detail", authRequired: true },
    component: () => import('./views/manifest/cargo-manifests-detail'),
},
{
    path: '/pages/delivery-material-requests',
    name: 'delivery-material-requests',
    meta: { title: "Vendor Delivery Requests", authRequired: true },
    component: () => import('./views/vendor-delivery/delivery-requests'),
},
{
    path: '/pages/delivery-exception-requests-detail/:ids?',
    name: 'delivery-exception-requests-detail',
    meta: { title: "Vendor Delivery Request Exceptions(s) Detail", authRequired: true },
    component: () => import('./views/vendor-delivery/request-exceptions-detail'),
},
{
    path: '/pages/delivery-exception-requests',
    name: 'delivery-exception-requests',
    meta: { title: "Delivery Exception Requests", authRequired: true },
    component: () => import('./views/vendor-delivery/request-exceptions'),
},
{
    path: '/pages/delivered-material-requests',
    name: 'delivered-material-requests',
    meta: { title: "Delivered Requests", authRequired: true },
    component: () => import('./views/vendor-delivery/delivered-requests'),
},
{
    path: '/pages/delivered-requests-detail/:ids?',
    name: 'delivered-requests-detail',
    meta: { title: "Delivered Request(s) Detail", authRequired: true },
    component: () => import('./views/vendor-delivery/delivered-requests-detail'),
},

{
    path: '/pages/approved-material-requests',
    name: 'approved-requests',
    meta: { title: "Approved Requests", authRequired: true },
    component: () => import('./views/pages/approved-material-requests'),
},
{
    path: '/pages/material-container-manifests',
    name: 'material-container-manifests',
    meta: { title: "Container Loadouts", authRequired: true },
    component: () => import('./views/pages/material-container-manifests'),
},
{
    path: '/pages/material-request-loadouts',
    name: 'material-request-loadouts',
    meta: { title: "Material Request Loadouts", authRequired: true },
    component: () => import('./views/pages/material-request-loadouts'),
},
{
    path: '/pages/request-exceptions',
    name: 'request-exceptions',
    meta: { title: "Requests Exceptions", authRequired: true },
    component: () => import('./views/pages/request-exceptions'),
},
{
    path: '/pages/material-requests-detail/:ids?',
    name: 'Material Request(s) Detail',
    meta: { title: "Material Request(s) Detail", authRequired: true },
    component: () => import('./views/pages/material-requests-detail'),
},
{
    path: '/pages/approved-material-requests-detail/:ids?',
    name: 'approved-material-requests-detail',
    meta: { title: "Approved Material Request(s) Detail", authRequired: true },
    component: () => import('./views/pages/approved-material-requests-detail'),
},
{
    path: '/pages/material-request-loadouts-detail/:ids?',
    name: 'material-request-loadouts-detail',
    meta: { title: "Approved Material Request(s) Detail", authRequired: true },
    component: () => import('./views/pages/material-request-loadouts-detail'),
},
{
    path: '/pages/material-container-manifests-detail/:ids?',
    name: 'material-container-manifests-detail',
    meta: { title: "Material Container Loadout(s) Detail", authRequired: true },
    component: () => import('./views/pages/material-container-manifests-detail'),
},
{
    path: '/pages/request-exceptions-detail/:ids?',
    name: 'request-exceptions-detail',
    meta: { title: "Request Exception(s) Detail", authRequired: true },
    component: () => import('./views/pages/request-exceptions-detail'),
},
{
    path: '/pages/404',
    name: 'Page-404',
    meta: { title: "Page 404", authRequired: true },
    component: () => import('./views/pages/error-404'),
},
{
    path: '/pages/500',
    name: 'Page-500',
    meta: { title: "Page 500", authRequired: true },
    component: () => import('./views/pages/error-500'),
},
{
    path: '/pages/maintenance',
    name: 'Maintenance',
    meta: { title: "Maintenance Page", authRequired: true },
    component: () => import('./views/pages/maintenance'),
},
{
    path: '/pages/login-1',
    name: 'Login-1',
    meta: { title: "Login", authRequired: true },
    component: () => import('./views/pages/login-1'),
},
{
    path: '/pages/login-2',
    name: 'Login-2',
    meta: { title: "Login 2", authRequired: true },
    component: () => import('./views/pages/login-2'),
},
{
    path: '/pages/register-1',
    name: 'Register-1',
    meta: { title: "Register", authRequired: true },
    component: () => import('./views/pages/register-1'),
},
{
    path: '/pages/register-2',
    name: 'Register-2',
    meta: { title: "Register 2", authRequired: true },
    component: () => import('./views/pages/register-2'),
},
{
    path: '/pages/recoverpwd-1',
    name: 'Recover Password 1',
    meta: { title: "Recover Password", authRequired: true },
    component: () => import('./views/pages/recoverpwd-1'),
},
{
    path: '/pages/recoverpwd-2',
    name: 'Recover Password 2',
    meta: { title: "Recover Password 2", authRequired: true },
    component: () => import('./views/pages/recoverpwd-2'),
},
{
    path: '/pages/lock-screen1',
    name: 'Lock-screen 1',
    meta: { title: "Lock Screen 1", authRequired: true },
    component: () => import('./views/pages/lock-screen1'),
},
{
    path: '/pages/lock-screen2',
    name: 'Lock-screen 2',
    meta: { title: "Lock Screen 2", authRequired: true },
    component: () => import('./views/pages/lock-screen2'),
},
]