// Odoo User Service
import axios from 'axios';
import { API_BASE_URL } from '../authservice/odoo.auth.service';
export const userProfileService = {
    getUserProfile,
    getCompanyProfileImageUrl
};

// Set headers for the preflight (OPTIONS) request
const options = {
    headers: {
        'Content-Type': 'application/json',
    },
};

async function getUserProfile() {
    try {
        const requestData = new URLSearchParams();
        let jwt = localStorage.getItem('user')
        requestData.append('jwt', jwt);
        const options = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        const response = await axios.post(`${API_BASE_URL}/api/portal/user_profile`, requestData, options);
        console.log(response)
        return response.data;
    } catch (error) {
        // Handle error
        throw error;
    }
}

async function getCompanyProfileImageUrl(userProfile) {
    return `${API_BASE_URL}/api/portal/company/${userProfile.company_profile_id}/profile_image`
}



// async function getUserProfile() {
//     try {
//         // Get the JWT token from localStorage
//         let jwt = localStorage.getItem('user');

//         // Prepare the JSON-RPC request
//         const jsonRpcRequest = {
//             jsonrpc: '2.0',
//             params: {
//                 jwt: jwt,
//                 db: 'prod_db_jan_6_2023_v15'
//             },
//         };

//         // Make the JSON-RPC request using axios or your preferred HTTP library
//         const response = await axios.post(`${API_BASE_URL}/api/portal/user_profile`, jsonRpcRequest, {
//             withCredentials: true
//         });
//         // const response = await fetch(`${API_BASE_URL}/api/portal/user_profile`, {
//         //     method: 'POST',
//         //     headers: {
//         //       'Content-Type': 'application/json',
//         //     },
//         //     body: JSON.stringify(jsonRpcRequest),
//         //     withCredentials: true

            
//         //   });

//         console.log(response);
//         return response.data.result;  // Assuming the server sends the result in the 'result' field
//     } catch (error) {
//         // Handle error
//         throw error;
//     }
// }


// Implement logout, register, and getAll similarly...

